@import url('https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600&display=swap');
::-webkit-scrollbar{width: 7px;}
::-webkit-scrollbar-track{background-color: darken(white, 40%)}
::-webkit-scrollbar-thumb{background-color: darken(white, 70%);border-radius: 5px;}
::-webkit-scrollbar:horizontal{height: 7px;}
::-webkit-scrollbar-track:horizontal{background-color: transparent;}
::-webkit-scrollbar-thumb:horizontal{background-color: darken(white, 70%);border-radius: 5px;}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Roboto;
  line-height: 1.5rem;
}
html{scroll-behavior: smooth;}
h1 {
  font-size: 1.8rem;
  font-weight: 400;
  letter-spacing: 1px;
  font-family: 'Raleway', sans-serif;
}
h2, h3{
  font-weight: 600;
  font-family: 'Raleway', sans-serif;
}
a {text-decoration: none;}
